<script lang="ts">
    import { escapeDownEvent$ } from "$lib/keys"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import { chevron_left } from "@timephy/tui-icons-svelte"
    import { getContext, onDestroy } from "svelte"
    import { Icon } from "svelte-awesome"
    import lang from "$lang"

    const modalVC = getContext<IModalVC>("ModalVC")
    const { count$: modalsCount$ } = modalVC

    export let clickBack: (() => void) | null = null
    export let escBack = true

    let elem: HTMLDivElement | null = null

    function back() {
        if (clickBack !== null) {
            clickBack()
        } else {
            modalVC.pop()
        }
    }

    export let text: string | null = null

    let subscriptionEscape = escapeDownEvent$.subscribe(() => {
        if (escBack) {
            back()
            // to stop pressing escape from focussing the button leading to this modal
            elem?.focus()
        }
    })
    onDestroy(() => subscriptionEscape.unsubscribe())
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
    class="link flex cursor-pointer items-center gap-[6px] truncate focus:outline-none"
    on:click={back}
    role="button"
    tabindex="0"
    bind:this={elem}
>
    <Icon data={chevron_left} class="h-6 w-6 shrink-0 sm:h-5 sm:w-5" />
    <p class="truncate text-sm">
        {text !== null ? text : $modalsCount$ > 1 ? $lang.Back() : $lang.Close()}
    </p>
</div>
