<script lang="ts">
    let className = ""
    export { className as class }

    import BackButton from "$components/BackButton.svelte"

    export let title: string
    export let backText: string | null = null

    export let style: "menu" | "title" = "menu"

    export let clickBack: (() => void) | null = null
    export let escBack = true
</script>

<div class="grid grid-cols-3 gap-2 {className}">
    <!-- using flex container to not expand button all over the w-1/3 cell -->
    <div class="flex">
        <BackButton text={backText} {clickBack} {escBack} />
    </div>
    <h1
        class="self-center text-center {style === 'menu'
            ? 'text-lg font-medium sm:text-base'
            : 'text-3xl font-semibold'}"
    >
        {title}
    </h1>
    <slot />
</div>
